<template>
  <div class="tsr-marketitem margin-top16" @click="onDetail">
    <van-row align="center" type="flex" justify="space-between">
      <van-col span="11" class="ub" >
        <div class="tsr-marketitem-info-currency">
          <div>
            <p class="symbol">{{ticker24h.symbol}}</p>
            <p class="feature font10 font-weight400">{{ $t('market.perprtual') }}</p>
            <span class="font10 font-weight500">{{ $t('market.vol') }}&nbsp;{{ticker24h.volume}}</span>
          </div>
        </div>
      </van-col>
      <van-col span="7" class="ub ub-tope pl12" >
        <div class="tsr-marketitem-info-price">
          <p :style="{ color: `var(${changeColor})` }" class="font16 font-weight500">{{ticker24h.last_price}}</p>
          <span class="font10 font-weight400">
            <span>
              <span style="margin-right: 2px">$</span>
              <span class="tsr-marketitem-info-price-value">{{ticker24h.last_price}}</span>
            </span>
          </span>
        </div>
      </van-col>
      <van-col span="6" class="ub ub-tope" >
        <div
          class="tsr-marketitem-rate"
          :style="{ backgroundColor: `var(${changeColor})` }"
        >
          {{(+ticker24h.price_change_percent||0).toFixed(2)}}%
        </div>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import { Row, Col } from 'vant'
import Vue from 'vue'
Vue.use(Row).use(Col)
export default {
  props: {
    symbol: {
      type: String,
      default: 'BTCUSDT'
    },
    ticker24h: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  computed: {
    changeColor () {
      let rate = ''
      if (this.ticker24h.price_change_percent > 0) {
        rate = '--up-color'
      }
      if (this.ticker24h.price_change_percent < 0) {
        rate = '--down-color'
      }
      if (this.ticker24h.price_change_percent === 0 || !this.ticker24h.price_change_percent) {
        rate = '--common-color'
      }
      return rate
    }
  },
  mounted () {
  },
  methods: {
    onDetail () {
      if (this.$route.name === 'Market') {
        this.$router.push({
          path: `/trade/spot-kline/${this.ticker24h.symbol}`
        })
      } else {
        this.$router.push(`/feature/${this.ticker24h.symbol}`)
      }
    }
  }
}
</script>
<style lang="less">
.tsr-marketitem {
  box-sizing: border-box;
  width: 100%;
  color: var(--main-text-1);
  .ub-tope {
    align-self: flex-start;
  }
  &-rate {
    width: 80px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    border-radius: 4px;
    background-color: var(--up-color);
  }
  &-info {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    &-currency {
      display: flex;
      align-items: center;
      word-break: break-all;
      flex: 1 1;
      font-size: 3.7vw;
      .symbol {
        color: #1c1b1e;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .feature {
        color: var(--main-text-3);
        font-family: Roboto;
        font-style: normal;
      }
      span {
        color: var(--main-text-2);
        font-family: Roboto;
        font-style: normal;
      }
    }
    &-price {
      text-align: right;
      &-fiat {
        color: var(--main-text-2);
        font-family: Roboto;
        font-style: normal;
      }
      &-vol {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
  &-info-price-value {
    color: var(--main-text-2);
  }
  .pl12 {
    padding-right: 24px;
  }
}
</style>

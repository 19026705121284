<template>
  <div class="market-favorites-wrapper">
    <MarketHeader @sortCallback = "sortFn" />
    <div class="coin-list">
      <!-- $t('pullRefresh.finishedText') -->
      <van-list
        v-if="requestedStatus === 'requested'&&symbolArray.length"
        v-model="loading"
        :finished="finished"
        :finished-text="' '"
        @load="onLoad"
        :loading-text="' '"
      >
        <div  v-for="item in symbolArray"
          :key="item">
          <MarketItem :ticker24h="coinObj[item]" />
        </div>
      </van-list>
      <div v-if="requestedStatus === 'requested'&& symbolArray.length && loadingStatus === 'error'" @click="refreshLoad" class="text-c margin-bottom30 active-color-1 padding30">{{ $t('refresh') }}</div>
      <NoData :refreshBtn="refreshBtnFlag" :tip="$t('market.noDataText')" @refresh="refreshFn" v-if="requestedStatus === 'requested'&& !symbolArray.length"> </NoData>
    </div>
  </div>
</template>
<script>
import { getToken } from '@/utils/token.js'
import { List, Toast } from 'vant'
import Vue from 'vue'
import MarketHeader from '@/components/market/header'
import MarketItem from '@/components/market/item'
import NoData from '@/components/market/noData'
import { optionalList, ticker24hList } from '@/api/market.js'

Vue.use(Toast)
Vue.use(List)

export default {
  components: {
    MarketHeader,
    MarketItem,
    NoData
  },
  data () {
    return {
      value: '',
      loading: false,
      finished: true,
      coinObj: {},
      symbolList: [],
      symbolArray: [],
      // normal 请求正常 error 请求报错
      loadingStatus: 'normal',
      // 有三种状态 1.还没请求 初始化的状态 pending 2. 断网状态 nointernet 3. 请求过 requested
      requestedStatus: 'pending',
      ticker24hListLoop: null,
      initLoadingToast: null,
      refreshBtnFlag: false
    }
  },
  mounted () {
    if (getToken()) {
      this.initLoadingToast = this.$toast.loading({
        mask: true,
        message: ' '
      })
      this.loading = true
      this.refreshBtnFlag = true
      this.optionalListFn()
    } else {
      this.requestedStatus = 'requested'
      this.refreshBtnFlag = false
      console.log('无token 未登录')
    }
  },
  beforeDestroy () {
    clearTimeout(this.ticker24hListLoop)
    this.ticker24hListLoop = null
    console.log('beforeDestroy ======market')
  },
  deactivated () {
  },
  methods: {
    refreshLoad () {
      this.finished = false
      this.loadingStatus = 'normal'
      this.ticker24hListLoopFn()
    },
    sortStatusFn (sortStatus, sortTarget) {
      // sortStatus 排序状态 up down pending
      // sortTarget 排序目标 价格 涨跌 交易量。。。
      const symbolArr = [...this.symbolList]
      let sortArr = symbolArr.map((item) => {
        const newItem = {
          symbol: item
        }
        newItem[sortTarget] = this.coinObj[item][sortTarget] || 0
        return newItem
      })
      if (sortStatus === 'pending') {
      }
      if (sortStatus === 'down') {
        sortArr = sortArr.sort((a, b) => {
          return b[sortTarget] - a[sortTarget]
        })
      }
      if (sortStatus === 'up') {
        sortArr = sortArr.sort((a, b) => {
          return a[sortTarget] - b[sortTarget]
        })
      }
      this.symbolArray = sortArr.map((item) => {
        return item.symbol
      })
    },
    sortFn (sortType) {
      const sortTargetHandlerObj = {
        name: () => {
          if (sortType.status === 'pending') {
            this.symbolArray = [...this.symbolList]
          }
          if (sortType.status === 'down') {
            this.symbolArray = this.symbolArray.sort((a, b) => {
              return parseInt(b[0], 36) - parseInt(a[0], 36)
            })
          }
          if (sortType.status === 'up') {
            this.symbolArray = this.symbolArray.sort((a, b) => {
              return parseInt(a[0], 36) - parseInt(b[0], 36)
            })
          }
        },
        vol: () => {
          this.sortStatusFn(sortType.status, 'volume')
        },
        price: () => {
          this.sortStatusFn(sortType.status, 'last_price')
        },
        change: () => {
          this.sortStatusFn(sortType.status, 'price_change_percent')
        }
      }
      sortTargetHandlerObj[sortType.target]()
    },
    emitEvent () {
      this.ticker24hListLoopFn()
    },
    cleaeEvent () {
      if (this.ticker24hListLoop) {
        clearTimeout(this.ticker24hListLoop)
        this.ticker24hListLoop = null
      }
    },
    refreshFn () {
      this.initData()
      this.optionalListFn()
      console.log('刷新')
    },
    initData () {
      this.requestedStatus = 'pending'
      this.pageNum = 1
      this.pageSize = 15
      this.loading = true
      this.symbolList = []
      this.symbolArray = []
      this.coinObj = {}
      this.initLoadingToast = this.$toast.loading({
        mask: true,
        message: ' '
      })
    },
    ticker24hListLoopFn () {
      console.log('all -----loop')
      if (this.ticker24hListLoop) {
        clearTimeout(this.ticker24hListLoop)
        this.ticker24hListLoop = null
      }
      const symbolArr = [...this.symbolList]
      console.log(symbolArr, 'symbolArr--------')
      if (symbolArr.length) {
        ticker24hList({
          symbol_list: symbolArr
        }).then((res) => {
          console.log(res, 'ticker24hListFn')
          if (res) {
            const resObj = {}
            for (let i = 0; i < res.length; i++) {
              resObj[res[i].symbol] = res[i]
            }
            for (let i = 0; i < symbolArr.length; i++) {
              if (!resObj[symbolArr[i]]) {
                resObj[symbolArr[i]] = {}
                resObj[symbolArr[i]].symbol = symbolArr[i]
              }
              this.$set(this.coinObj, resObj[symbolArr[i]].symbol, resObj[symbolArr[i]])
            }
            this.$toast.clear()
            this.$forceUpdate()
          }
        })
      }
      this.ticker24hListLoop = setTimeout(() => {
        this.ticker24hListLoopFn()
      }, 3000)
    },
    optionalListFn () {
      optionalList({
      }).then((res) => {
        if (res) {
          this.symbolList = res.data?.symbols || []
          const symbolArr = [...this.symbolList]
          this.$store.dispatch('updateFavoritesMarket', symbolArr)
          this.ticker24hListFn(symbolArr)
        } else {
          console.log('else')
          this.loadingStatus = 'error'
          this.requestedStatus = 'requested'
          if (this.ticker24hListLoop) {
            clearTimeout(this.ticker24hListLoop)
            this.ticker24hListLoop = null
          }
          if (this.initLoadingToast) {
            this.initLoadingToast.clear()
            this.initLoadingToast = null
          }
        }
        this.$toast.clear()
      })
    },
    ticker24hListFn (symbolArr) {
      ticker24hList({
        symbol_list: symbolArr
      }).then((res) => {
        const resObj = {}
        for (let i = 0; i < res.length; i++) {
          resObj[res[i].symbol] = res[i]
        }
        for (let i = 0; i < symbolArr.length; i++) {
          if (!resObj[symbolArr[i]]) {
            resObj[symbolArr[i]] = {}
            resObj[symbolArr[i]].symbol = symbolArr[i]
          }
          this.$set(this.coinObj, resObj[symbolArr[i]].symbol, resObj[symbolArr[i]])
        }
        this.requestedStatus = 'requested'
        this.symbolArray = [...this.symbolList]
        this.loading = false
        if (this.initLoadingToast) {
          this.initLoadingToast.clear()
          this.initLoadingToast = null
        }
      })
    },
    onLoad () {
      if (this.loadingStatus === 'normal') {
      }
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/styles/mixins.less";
.market-favorites-wrapper{
  min-height: 100%;
  padding-bottom: 140px;
  // .coin-list{
  //   height: calc(100% - 120px);
  //   overflow-y: scroll;
  // }
}
</style>

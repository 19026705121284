<template>
  <div class="markethead">
    <van-row type="flex" justify="space-between">
      <van-col :span="tab.span"  v-for="tab in tabs" :key="tab.value" class="ub" :class="tab.class">
        <div v-for="(tabText, tabTextIdx) in tab.label" :key="tabTextIdx" class="ub ub-cen tab-text">
          <div class="ub ub-cen tab-text" @click="sortType(tabText)">
            <span v-if="tabTextIdx != 0" class="font12 font-weight400"> / </span>
            <span>
              {{ $t(`market.${tabText}`) }}
            </span>
            <img :src="iconStatus[tabsStatus[tabText]]"/>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import { Row, Col } from 'vant'
import Vue from 'vue'
Vue.use(Row).use(Col)
const sortAll = require('@/assets/images/system/sort-all.svg')
const sortDown = require('@/assets/images/system/sort-down.svg')
const sortUp = require('@/assets/images/system/sort-down.svg')
export default {
  data () {
    return {
      iconStatus: {
        pending: sortAll,
        up: sortUp,
        down: sortDown
      },
      tabsStatus: {
        // pending up down
        name: 'pending',
        vol: 'pending',
        price: 'pending',
        change: 'pending'
      },
      tabs: [
        { label: ['name', 'vol'], span: 11, value: 1, cls: 'currency', class: '' },
        { label: ['price'], value: 2, span: 7, cls: 'price', class: 'ub-tope pl12' },
        { label: ['change'], value: 3, span: 6, cls: 'rate', class: 'ub-tope' }
      ]
    }
  },
  methods: {
    onSwitch () {},
    sortType (key) {
      const changeStatusObj = {
        pending: 'down',
        down: 'up',
        up: 'pending'
      }
      const keyStatus = changeStatusObj[this.tabsStatus[key]]
      this.tabsStatus = {
        // pending up down
        name: 'pending',
        vol: 'pending',
        price: 'pending',
        change: 'pending'
      }
      this.tabsStatus[key] = keyStatus
      const sortObj = {
        target: key,
        status: this.tabsStatus[key]
      }
      this.$emit('sortCallback', sortObj)
    }
  }
}
</script>
<style lang="less">
.markethead{
  color: var(--main-text-3);
  .divider {
    display: inline-block;
    margin: 0 8px;
    color: var(--main-text-3);
    font-family: Roboto;
    font-style: normal;
    text-transform: capitalize;
  }
  .font12 {
    width: 20px;
    display: inline-block;
    text-align: center;
  }
  img {
    margin-left: 4px;
    width: 8px;
    height: 18px;
  }
  .currency {
    text-align: left;
    flex: 1 1;
  }
  .price {
    width: 33.33%;
    padding-right: 5.33333vw;
    justify-content: end;
  }
  .rate {
    width: 24vw;
    justify-content: end;
  }

  .pl12 {
    padding-right: 44px;
  }
}
</style>
